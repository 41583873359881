import React, { Dispatch, useEffect, useState } from 'react';
import './Project.scss';

import { connect, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Tab, Tabs } from 'carbon-components-react';

import { IProject } from '../../../interfaces/project';
import { initialProject } from '../../../constants/initial-project';
import { requestProjectAction } from '../../../store/project/project.action';
import { getQueryParams } from '../../../scripts/get-query-params';

import Details from './Details';
import Positions from './Positions';
import Team from './Team';

type propTypes = {
  dispatch: Dispatch<any>
}

function Project({ dispatch }: propTypes) {
  const history = useHistory();

  const { projectId } = useParams();

  const { project, createdProject, newProjectName } = useSelector((state: any) => state.projectReducer);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [projectData, setProjectData] = useState<IProject>({ ...initialProject });

  const components = [Details, Positions, Team];
  const tabs = ['Details', 'Positions', 'Team'];

  useEffect(() => {
    if (projectId === 'new-project') {
      setProjectData({ ...initialProject });
      setSelectedTabIndex(0);
    } else {
      dispatch(requestProjectAction(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId !== 'new-project') {
      setProjectData(project);
    }
  }, [project])

  useEffect(() => {
    const { params } = getQueryParams();

    let tabIndex = 0;
    if (params.tab) {
      tabIndex = tabs.findIndex(tab => tab.toLowerCase().match(params.tab));
    }

    setSelectedTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [project.uuid]);

  useEffect(() => {
    if (createdProject.uuid) {
      history.push(`/home/projects/${createdProject.uuid}`);
    }
  }, [createdProject]);

  const handleTabClick = (index: number) => {
    if (selectedTabIndex !== index) {
      dispatch(requestProjectAction(projectId));
      setSelectedTabIndex(index);
    }
  }

  return (
    <div className="c-project">
      <div className="c-project__main-wrapper">
        <div className="c-header">
          <h1 className="c-title">{projectId === 'new-project' ? newProjectName : project.name}</h1>
        </div>
        <div className="c-project__main">
          <Tabs className="tabs-wrapper" selected={selectedTabIndex}>
            {components.map((Component, index) => (
              <Tab
                key={index}
                href="#"
                id={'tab-' + index}
                label={
                  tabs[index] === 'Team' && projectData.applications?.length ? (
                      <>{tabs[index]}<span className="bx--indication"></span></>
                  ) : tabs[index]
                }
                className="tab-item"
                onClick={() => handleTabClick(index)}
                disabled={projectId === 'new-project'}
              >
                {<Component project={projectData}/>}
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default connect()(Project);
