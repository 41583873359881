import React, { Dispatch, useEffect, useState } from 'react';
import './Auth.scss';

import { useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form, TextInput, Loading } from 'carbon-components-react';

import { loginUser, logoutUser } from '../../store/user/user.action';
import { emailRegExp } from '../../constants/email-regexp';
import { encryptPassword } from "../../services/encrypt";
import { getQueryParams } from '../../scripts/get-query-params';

import ForgotPassword from './ForgotPassword';

import Logo from '../Logo';

type propTypes = {
  dispatch: Dispatch<any>
}

function Auth({ dispatch }: propTypes) {
  const history = useHistory();

  const { isUserRequestInProgress, userRequestError, user } = useSelector((state: any) => state.userReducer);

  const [state, setState] = useState({ validateInputs: false });
  const [error, setError] = useState({ type: '', message: '' });
  const [credentials, setCredentials] = useState({ password: '', email: '' });
  const [isModalActive, setModalActiveStatus] = useState(false);
  const [isReadyToLogin, setIsReadyToLogin] = useState(false);

  useEffect(() => {
    setError(userRequestError);
  }, [userRequestError]);

  useEffect(() => {
    const { params } = getQueryParams();
    const { queryLink } = getQueryParams(['email', 'password']);

    if (params.action === 'reset_password' && params.email && params.password && !isReadyToLogin) {
      dispatch(logoutUser());
      setIsReadyToLogin(true);
      setCredentials({email: params.email, password: params.password});
    } else if (user?.uuid) {
      history.push({
        pathname: '/home',
        search: queryLink
      });
    }

  }, [user]);

  const handleChange = (field: string, value: string): void => {
    const newCredentials = credentials;
    newCredentials[field] = value;
    setCredentials(newCredentials);

    if (state.validateInputs) {
      validateForm();
    } else {
      setError({ type: '', message: '' });
    }
  };

  const validateForm = (): boolean => {
    if (!emailRegExp.test(credentials.email)) {
      setError({ type: 'email', message: 'Please enter a valid email address.' });
      return false;
    }

    if (credentials.password.length < 6) {
      setError({ type: 'password', message: 'Password should contain at least 6 characters.' });
      return false;
    }

    setError({ type: '', message: '' });
    return true;
  };

  const login = (e): void => {
    e.preventDefault();

    const isFormValid = validateForm();

    if (isFormValid) {
      const encryptedPassword = encryptPassword(credentials.password);
      dispatch(loginUser(credentials.email, encryptedPassword));
    } else {
      setState({ validateInputs: true });
    }
  };

  const setModalOpenedStatus = (active: boolean): void => {
    setModalActiveStatus(active);
  }

  return (
    <div className="c-auth-wrapper">
      {isUserRequestInProgress && <Loading/>}

      <ForgotPassword setModalOpenedStatus={setModalOpenedStatus} isOpened={isModalActive}/>

      <div className="c-auth">
        <Logo/>

        <p className="c-auth__description">
          Where the world’s top creative minds come together for the world’s smartest brands and its most urgent causes.
        </p>

        <Form className="c-login-form" onSubmit={e => login(e)}>
          <TextInput labelText="Admin Email Address"
                     placeholder="Admin email"
                     autoComplete="username"
                     id="email"
                     type="email"
                     className="auth__input"
                     value={credentials.email}
                     invalid={error.type === 'email'}
                     invalidText={error.message}
                     onChange={e => handleChange('email', e.target.value)}
          />

          <TextInput labelText="Password"
                     placeholder="Password"
                     autoComplete="current-password"
                     id="password"
                     type="password"
                     value={credentials.password}
                     invalid={error.type === 'password'}
                     invalidText={error.message}
                     onChange={e => handleChange('password', e.target.value)}
          />

          <Button className="c-login-form__button" type="submit">Sign In</Button>
        </Form>

        <p className="c-auth__help">
          <span className="c-auth__help__link" onClick={() => setModalOpenedStatus(true)}>Login Help</span>
        </p>
      </div>
    </div>
  );
}

export default connect()(Auth);
