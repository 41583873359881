import React, { useEffect, useState } from 'react';
import './User.scss'

import classnames from 'classnames';
import {connect, useSelector} from 'react-redux';
import { OverflowMenu, OverflowMenuItem } from 'carbon-components-react';

import { IUser } from '../../interfaces/user';

import noPhoto from '../../assets/images/no_photo.png';
import {useParams} from "react-router-dom";

type propTypes = {
  user: IUser,
  buttons: string[],
  handleSuccess?: Function,
  handleMakeAdmin?: Function,
  handleError: Function,
  isAccount?: boolean,
  userRole?: string,
  scrollTop?: number,
  indication?: boolean,
  canManage?: boolean
}

function User({ user, buttons, handleError, handleSuccess, handleMakeAdmin, isAccount, userRole, scrollTop, indication, canManage }: propTypes) {
  const [isOptionsOpened, setOptionsOpenedValue] = useState(false);
  const currentUser = useSelector((state: any) => state.userReducer.user);

  useEffect(() => {
    setOptionsOpenedValue(false);
  }, [scrollTop]);

  return (
    <div className={
      classnames({
        'c-user': true,
        'c-user--account': isAccount
      })
    }>
      <div className="c-user__image">
        <img src={user.photo ? user.photo : noPhoto}
             alt="User's photo"
             draggable="false"
        />
      </div>

      <div className="c-user__info">
        {isAccount && (
          <p className="c-user__label">Account</p>
        )}

        <p className="c-user__name">
          {user.firstName + ' ' + user.lastName} {indication && <span className="bx--indication"></span>}
        </p>

        {!isAccount && (
          <div className="c-user__details">
            <p className="c-user__detail">{user.email}</p>
            <p className="c-user__detail">{user.phone}</p>
            {userRole === 'creators' && (
              <>
                {user.primarySkills && <p className="c-user__detail">{user.primarySkills}</p>}
                {user.secondarySkills && <p className="c-user__detail">{user.secondarySkills}</p>}
                {user.website && <p className="c-user__detail">{user.website}</p>}
                {user.position && <p className="c-user__detail">{user.position}</p>}
                {user.rate && <p className="c-user__detail">${user.rate}/hr</p>}
                {user.supportedCauses && <p className="c-user__detail">{user.supportedCauses}</p>}
                {user.sohoHouseMemberNumber && <p className="c-user__detail">{user.sohoHouseMemberNumber}</p>}
              </>
            )}
          </div>
        )}
      </div>

      {canManage && (
        <div className="c-user__button-wrapper">
          <OverflowMenu direction={isAccount ? 'top' : 'bottom'}
                        flipped
                        iconClass="c-user__options-btn"
                        menuOptionsClass={isAccount ? 'bx--overflow-menu-options--secondary' : 'bx--overflow-menu-options--primary'}
                        className={isAccount ? 'bx--overflow-menu--secondary' : 'bx--overflow-menu--primary'}
                        open={isOptionsOpened}
                        onClick={() => setOptionsOpenedValue(!isOptionsOpened)}
          >
            {buttons[2] && userRole !== 'admins' && (
                <OverflowMenuItem wrapperClassName={'c-options'}
                                  className={isAccount ? 'c-user__options-btn__text--primary' : 'c-user__options-btn__text--secondary'}
                                  itemText={buttons[2]}
                                  onClick={() => handleMakeAdmin && handleMakeAdmin(user)}
                />
            )}
            {buttons[1] && (
              <OverflowMenuItem wrapperClassName={'c-options'}
                                className={isAccount ? 'c-user__options-btn__text--primary' : 'c-user__options-btn__text--secondary'}
                                itemText={buttons[1]}
                                onClick={() => handleSuccess && handleSuccess(user)}
              />
            )}

            {buttons[0] && (currentUser.uuid !== user.uuid || buttons[0] !== 'Delete') && (
              <OverflowMenuItem className={'c-user__options-btn__text--error'}
                                itemText={buttons[0]}
                                onClick={() => handleError(user)}
              />
            )}
          </OverflowMenu>
        </div>
      )}
    </div>
  )
}

export default connect()(User);
