import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';

import userReducer from './user/user.reducer';
import projectReducer from './project/project.reducer';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combineReducers({
    userReducer,
    projectReducer
  }),
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
