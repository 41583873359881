import React, { useEffect, useState } from 'react';
import './ConfirmationPopUp.scss';

import { Button, Modal } from 'carbon-components-react';

import { initialConfirmData } from '../../constants/initial-confirm-data';

import Logo from '../Logo';

type propTypes = {
  title: string,
  message: string,
  handler: Function,
  close: Function,
  buttons: string[]
}

function ConfirmationPopUp({ title, message, handler, close, buttons }: propTypes) {
  const [opened, setOpened] = useState(false);
  const [data, setData] = useState({ ...initialConfirmData });

  useEffect(() => {
    setOpened(!!message);

    if (message) {
      setData({ title, message, buttons });
    }
  }, [message, title]);

  return (
    <Modal open={opened}
           modalHeading={<Logo />}
           passiveModal
           onRequestClose={() => close()}
    >
      <div className="c-confirm">
        <h3 className="c-confirm__title">{data.title}</h3>
        <p className="c-confirm__description">{data.message}</p>

        <div className="c-confirm__buttons">
          <Button className="c-confirm__button bx--btn--primary"
                  type="button"
                  onClick={handler}
          >
            {data.buttons[1]}
          </Button>
          <Button className="c-confirm__button c-confirm__button--cancel bx--btn--secondary"
                  type="button"
                  onClick={close}
          >
            {data.buttons[0]}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationPopUp;
