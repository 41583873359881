import React, { useEffect, useState } from 'react';
import './InputField.scss';

import { TextArea, TextInput } from 'carbon-components-react';

import { emailRegExp } from '../../constants/email-regexp';
import {useSelector} from "react-redux";

type propTypes = {
  type: 'textInput' | 'textArea',
  label: string,
  placeholder: string,
  rows?: number,
  handlePasswords?: { newPassword: string, repeatNewPassword: string },
  id: string,
  name: string,
  inputType: 'text' | 'email' | 'number' | 'password',
  value: string,
  showErrors: boolean,
  handleChange: Function,
  countErrors: Function,
  disabled?: boolean
}

function InputField({ type, label, placeholder, rows, handlePasswords, id, name, inputType, value, showErrors, handleChange, countErrors, disabled }: propTypes) {
  const [errorText, setErrorText] = useState('');
  const userRequestError = useSelector((state: any) => state.userReducer.userRequestError)

  useEffect(() => {
    if (isValid()) {
      countErrors('remove', name);
    } else {
      countErrors('add', name);
    }
  }, [value])

  useEffect(() => {
    if (userRequestError?.type === 'fb_user_creation' && inputType === 'email') {
      setErrorText(userRequestError.message);
    }
    if (
        userRequestError && id === 'profile-old-password' &&
        (userRequestError.type === 'reauthenticate' || userRequestError.type === 'password_change')
    ) {
      setErrorText('Current password is not valid');
    }
  }, [userRequestError])

  const isValid = (): boolean => {
    if (inputType === 'email' && !emailRegExp.test(value)) {
      setErrorText('Please enter a valid email address.');
      return false;
    }

    if (name === 'phone') {
      if (!value) {
        setErrorText('This field is required.');
        return false;
      } else if (!/[0-9]*$/.test(value)) {
        setErrorText('Phone number should contain only numbers.');
        return false;
      }
    }

    if (inputType === 'text' && (!value || value.length < 2)) {
      setErrorText('This field should contain at least 2 symbols.');
      return false;
    }

    if (inputType === 'password' && (!value || value.length < 6)) {
      setErrorText('This field should contain at least 6 characters.');
      return false;
    }

    if (handlePasswords?.newPassword !==  handlePasswords?.repeatNewPassword) {
      setErrorText('Password fields should equal.');
      return false;
    }

    setErrorText('');
    return true;
  }

  return type === 'textArea' ? (
    <TextArea labelText={label}
              placeholder={placeholder}
              autoComplete={name}
              id={id}
              value={value}
              invalid={!!errorText && showErrors}
              invalidText={errorText}
              name={name}
              className="text-input__dark-bg"
              rows={rows}
              onChange={e => handleChange(name, e.target.value)}
              disabled={typeof disabled !== 'undefined' && disabled}
    />
  ) : (
    <TextInput labelText={label}
               placeholder={placeholder}
               autoComplete={name}
               id={id}
               value={value}
               invalid={!!errorText && showErrors}
               invalidText={errorText}
               name={name}
               type={inputType}
               className="text-input__dark-bg"
               onChange={e => handleChange(name, e.target.value)}
               disabled={typeof disabled !== 'undefined' && disabled}
    />
  )
}

export default InputField;
