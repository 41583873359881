import firebase from '../../services/firebase';
import sendRequest from '../../services/request';
import { encryptPassword } from '../../services/encrypt';
import { filterEmptyFields } from '../../scripts/filter-empty-fields';

export enum ActionTypes {
  REQUEST = 'REQUEST',
  REQUEST_ERROR = 'REQUEST_ERROR',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  VERIFY_FAILURE = 'VERIFY_FAILURE',
  PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS',
  PASSWORD_RESET_EMAIL_ERROR = 'PASSWORD_RESET_EMAIL_ERROR',
  RECEIVE_USERS_BY_ROLE = 'REQUEST_USERS_BY_ROLE',
  PROFILE_ACTION_REQUEST = 'PROFILE_ACTION_REQUEST',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  MAKE_ADMIN_SUCCESS = 'MAKE_ADMIN_SUCCESS',
  CLEAR_USER_REQUEST = 'CLEAR_USER_REQUEST',
  PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS'
}

const request = () => {
  return {
    type: ActionTypes.REQUEST
  };
};

const requestError = (type: string, error: { title: string, message: string }) => {
  return {
    type: ActionTypes.REQUEST_ERROR,
    payload: {
      userRequestError: { type: type || 'other', ...error }
    }
  }
}

const passwordChangeSuccess = () => {
  return {
    type: ActionTypes.PASSWORD_CHANGE_SUCCESS
  };
}

const receiveLogin = user => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    payload: { user }
  };
};

const loginError = (error) => {
  let type;
  let message;

  switch (error.code) {
    case 'auth/invalid-email':
      type = 'email';
      message = 'User email is not valid.'
      break;
    case 'auth/user-not-found':
      type = 'email';
      message = 'There is no user corresponding to the given email.'
      break;
    case 'auth/wrong-password':
      type = 'password';
      message = 'Password is invalid.'
      break;
    case 'auth/too-many-requests':
      type = 'other';
      message = 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
      break;
    default:
      type = 'other';
      message = 'Invalid login credentials';
      break;
  }

  return {
    type: ActionTypes.LOGIN_FAILURE,
    payload: {
      userRequestError: { type, message }
    }
  };
};

const receiveLogout = () => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS
  };
};

const verifyFailure = () => {
  return {
    type: ActionTypes.VERIFY_FAILURE
  };
};

const sendPasswordResetEmailSuccess = () => {
  return {
    type: ActionTypes.PASSWORD_RESET_EMAIL_SUCCESS
  };
};

const sendPasswordResetEmailError = (error) => {
  let message;
  switch (error.code) {
    case 'auth/invalid-email':
      message = 'User email is not valid.'
      break;
    case 'auth/user-not-found':
      message = 'There is no user corresponding to the given email.'
      break;
    default:
      message = 'User email is not valid.';
      break;
  }

  return {
    type: ActionTypes.PASSWORD_RESET_EMAIL_ERROR,
    payload: {
      userRequestError: { type: 'reset', message }
    }
  };
};

const receiveUsers = (users, role) => {
  return {
    type: ActionTypes.RECEIVE_USERS_BY_ROLE,
    payload: { users, role }
  }
}

const userCreated = (user, role) => {
  return {
    type: ActionTypes.CREATE_USER_SUCCESS,
    payload: { user, role }
  }
}

const userUpdated = (user) => {
  return {
    type: ActionTypes.UPDATE_USER_SUCCESS,
    payload: { ...user }
  }
}

const makeAdmin = (uuid) => {
  return {
    type: ActionTypes.MAKE_ADMIN_SUCCESS,
    payload: uuid
  }
}

export const userClearRequest = () => {
  return {
    type: ActionTypes.CLEAR_USER_REQUEST,
  }
}

const userDeleted = (user) => {
  return {
    type: ActionTypes.DELETE_USER_SUCCESS,
    payload: {
      deletedUserUuid: user.uuid
    }
  }
}


export const requestProfileAction = (user, action) => {
  return {
    type: ActionTypes.PROFILE_ACTION_REQUEST,
    payload: {
      profile: user,
      profileAction: action
    }
  }
}

export const cancelProfileAction = () => {
  return {
    type: ActionTypes.PROFILE_ACTION_REQUEST,
    payload: {
      profile: {},
      profileAction: null
    }
  }
}

export const loginUser = (email, password) => async dispatch => {
  dispatch(request());

  await firebase.auth().signInWithEmailAndPassword(email, password)
    .catch(error => {
      dispatch(loginError(error));
    });
  if (firebase.auth().currentUser) {
    getUser(dispatch);
  }
};

export const verifyAuth = () => dispatch => {
  dispatch(request());

  firebase
    .auth()
    .onAuthStateChanged(user => {
      if (user) {
        getUser(dispatch);
      } else {
        dispatch(verifyFailure());
      }
    });
};

export const logoutUser = () => dispatch => {
  dispatch(request());

  firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(receiveLogout());
    });
};

export const passwordResetEmail = (email) => async dispatch => {
  dispatch(request());

  try {
    const sendPasswordResetEmail = firebase
        .functions()
        .httpsCallable('webFunctions-sendPasswordResetEmail')

    await sendPasswordResetEmail({ email, source: 'admin', adminUrl: window.location.origin })
    dispatch(sendPasswordResetEmailSuccess());
  } catch (error) {
    dispatch(sendPasswordResetEmailError(error));
  }
}

export const deleteUserRequest = (user) => async dispatch => {
  dispatch(request());

  sendRequest('DELETE', '/api/users/' + user.uuid)
    .then(() => {
      dispatch(userDeleted(user));
    })
    .catch(err => {
      dispatch(requestError('other', err));
    });
}

export const getUsersByRole = role => async dispatch => {
  dispatch(request());

  sendRequest('GET', '/api/users/' + role)
    .then(users => {
      dispatch(receiveUsers(users, role));
    })
    .catch(err => {
      dispatch(requestError('other', err));
    });
}

function getUser(dispatch) {
  sendRequest('GET', '/api/user')
    .then(user => {
      dispatch(receiveLogin(user));
    })
    .catch(err => {
      logoutUser()(dispatch);
      dispatch(requestError('other', err));
    });
}

export const changePassword = ({ password, newPassword }) => async dispatch => {
  dispatch(request());

  const encryptedPassword = encryptPassword(password);
  const encryptedNewPassword = encryptPassword(newPassword);

  const user: any = firebase.auth().currentUser;
  const creds = firebase.auth.EmailAuthProvider.credential(
    user.email,
    encryptedPassword
  );

  user.reauthenticateWithCredential(creds)
    .then(data => {
      user.updatePassword(encryptedNewPassword)
        .then(data => {
          dispatch(passwordChangeSuccess());
        })
        .catch(err => {
          dispatch(requestError('password_change', { title: '', message: err.message }));
        });
    })
    .catch(err => {
      dispatch(requestError('reauthenticate', { title: '', message: err.message }));
    });
}

const updateUser = (userToUpdate, dispatch, admin?) => {
  const { uuid, ...rest } = userToUpdate;
  sendRequest('PUT', `/api/users/${uuid}`, { user: { ...rest } })
    .then((user) => {
      if (admin) {
        dispatch(makeAdmin(uuid));
      }
      dispatch(userUpdated({ uuid, ...rest }));
    })
    .catch(err => {
      dispatch(requestError('user_updating', err));
    });

}
export const createUserRequest = (data, role) => async dispatch => {
  const { firstName, lastName, email, password, phone, photo, sohoHouseMemberNumber } = data;
  const encryptedPassword = encryptPassword(password);
  const updatedUser = filterEmptyFields({ firstName, lastName, email, phone, photo, sohoHouseMemberNumber })

  dispatch(request());

  const createUser = firebase.functions().httpsCallable('adminFunctions-createUser');

  createUser({ email, password: encryptedPassword, emailVerified: true })
      .then(data => {
        sendRequest('POST', '/api/user', {user: {...updatedUser, password, role}})
            .then((user) => {
              dispatch(userCreated(user, role));
            })
            .catch(err => {
              dispatch(requestError('user_creation', err));
            });
      })
      .catch(err => {
        dispatch(requestError('fb_user_creation', { title: '', message: err.message }));
      });
}

export const updateUserRequest = (data, makeAdmin?) => async dispatch => {
  const { firstName, lastName, email, password, phone, photo, uuid, sohoHouseMemberNumber } = data;
  const updatedUser = filterEmptyFields({ uuid, firstName, lastName, email, phone, photo, sohoHouseMemberNumber })
  dispatch(request());

  if (password) {
    const encryptedPassword = encryptPassword(password);
    const fbUpdateUser = firebase.functions().httpsCallable('adminFunctions-updateUser');

    fbUpdateUser({ email, password: encryptedPassword })
      .then(data => {
        updateUser(updatedUser, dispatch)
      })
      .catch(err => {
        dispatch(requestError('fb_user_updating', err));
      });
  } else {
    if (makeAdmin) {
      updatedUser.role = 'admin';
      updateUser(updatedUser, dispatch, true);
    } else {
      updateUser(updatedUser, dispatch);
    }
  }
}
