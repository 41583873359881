interface IConfirmData {
  title: string;
  message: string;
  buttons: string[]
}

export const initialConfirmData: IConfirmData = {
  title: '',
  message: '',
  buttons: []
}
