import React, {useEffect, useState} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Loading, Modal } from 'carbon-components-react';

import {
  ActionTypes,
  cancelProfileAction,
  createUserRequest, requestProfileAction,
  updateUserRequest,
  userClearRequest
} from '../../store/user/user.action';
import { IProfile } from '../../interfaces/profile';

import Logo from '../Logo';
import InputField from '../InputField';
import ImageUploader from '../ImageUploader';
import ChangePassword from './ChangePassword';

import './Profile.scss';
import {getQueryParams} from "../../scripts/get-query-params";

type propTypes = {
  isAdmin: boolean // TODO: replace with "role: 'creator' | 'project_manager' | 'admin'"
}

function Profile({ isAdmin }: propTypes) {
  const dispatch = useDispatch();
  const { profile, user, profileAction, isUserRequestInProgress, userRequestSuccess } = useSelector((state: any) => state.userReducer);
  const { isProjectRequestInProgress } = useSelector((state: any) => state.projectReducer);
  const addNew = profileAction === 'add';
  const currentUser = !addNew && profile.uuid === user.uuid;
  const initialProfile: IProfile = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    phone: '',
    photo: '',
  }
  const [profileFields, setProfileFields] = useState({ ...initialProfile });
  const [isOpened, setIsOpenedValue] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    const { params } = getQueryParams();
    if (params.action === 'reset_password') {
      dispatch(requestProfileAction(user, 'account'));
      setChangePassword(true);
    }
  }, []);

  useEffect(() => {
    setIsOpenedValue(!!profileAction);
    if (profile && Object.keys(profile).length) {
      const { firstName, lastName, email, password, phone, photo } = profile;
      setProfileFields({
        firstName,
        lastName,
        email,
        password: password || '',
        phone,
        photo,
      })
    }
  }, [profile]);

  useEffect(() => {
    if (
      userRequestSuccess?.success &&
      (userRequestSuccess.action === ActionTypes.CREATE_USER_SUCCESS ||
        userRequestSuccess.action === ActionTypes.UPDATE_USER_SUCCESS)
    ) {
      dispatch(userClearRequest());
      handleClose();
    }
  }, [userRequestSuccess]);

  const handleClose = () => {
    setProfileFields({ ...initialProfile });
    setChangePassword(false);
    setShowErrors(false);
    dispatch(cancelProfileAction());
  }

  const handleChange = (field: string, value: string): void => {
    setProfileFields({
      ...profileFields,
      [field]: value
    });
  };

  const countErrors = (action: string, field: string) => {
    if (action === 'add' && !errors.includes(field)) {
      setErrors([...errors, field]);
    }

    if (action === 'remove' && errors.includes(field)) {
      const newErrors = errors.filter(error => error !== field);
      setErrors(newErrors);
    }
  }

  const submit = (e): void => {
    e.preventDefault();

    if (addNew && errors.length) {
      setShowErrors(true);
      return;
    } else if (errors.length > 1 || errors.length && errors[0] !== 'password') {
      setShowErrors(true);
      return;
    }

    if (addNew) {
      setShowErrors(true);
      const fields = {...profileFields, sohoHouseMemberNumber: 0};
      dispatch(createUserRequest(fields, isAdmin ? 'admin' : 'project_manager'));
    } else {
      const fields = {...profileFields, sohoHouseMemberNumber: Number(profile.sohoHouseMemberNumber)};
      dispatch(updateUserRequest({ ...fields, uuid: profile.uuid }));
    }
  };

  return (
    <Modal open={isOpened}
           modalHeading={<Logo />}
           passiveModal
           onRequestClose={() => handleClose()}
    >
      <div className="c-profile" hidden={changePassword}>
        {(isUserRequestInProgress || isProjectRequestInProgress) && <Loading className={'c-main__loading'}/>}
        <p className="modal-title m-bottom-40">{addNew ? 'Add Project Manager' : 'Edit profile'}</p>
        <Form onSubmit={e => submit(e)}>

          <div className="l-list l-list--split">
            <div className="l-list__item">
              <InputField type="textInput"
                          label="First name"
                          placeholder="Name"
                          id="profile-first-name"
                          name="firstName"
                          inputType="text"
                          value={profileFields.firstName}
                          showErrors={showErrors}
                          handleChange={handleChange}
                          countErrors={countErrors}
              />
            </div>
            <div className="l-list__item">
              <InputField type="textInput"
                          label="Last name"
                          placeholder="Name"
                          id="profile-last-name"
                          name="lastName"
                          inputType="text"
                          value={profileFields.lastName}
                          showErrors={showErrors}
                          handleChange={handleChange}
                          countErrors={countErrors}
              />
            </div>
            <div className="l-list__item">
              <InputField type="textInput"
                          label="Email"
                          placeholder="Email"
                          id="profile-email"
                          name="email"
                          inputType="email"
                          value={profileFields.email}
                          showErrors={showErrors}
                          handleChange={handleChange}
                          countErrors={countErrors}
                          disabled={!addNew}
              />
            </div>
            <div className="l-list__item" hidden={!addNew}>
              <InputField type="textInput"
                          label="Password"
                          placeholder="Password"
                          id="profile-password"
                          name="password"
                          inputType="password"
                          value={profileFields.password}
                          showErrors={showErrors}
                          handleChange={handleChange}
                          countErrors={countErrors}
              />
            </div>
            <div className="l-list__item">
              <InputField type="textInput"
                          label="Phone number"
                          placeholder="Phone"
                          id="profile-phone"
                          name="phone"
                          inputType="text"
                          value={profileFields.phone}
                          showErrors={showErrors}
                          handleChange={handleChange}
                          countErrors={countErrors}
              />
            </div>
          </div>

          <div className="m-bottom-25" hidden={addNew}>
            <Button
              className="c-details-form__button"
              kind="secondary"
              type="button"
              hidden={addNew}
              onClick={() => setChangePassword(true)}
            >
              Change Password
            </Button>
          </div>
          <ImageUploader type="photo"
                         resolution="108x108"
                         image={profileFields.photo}
                         title="Title Photo"
                         btnKind="secondary"
                         showErrors={showErrors}
                         handleChange={handleChange}
                         countErrors={countErrors}
                         optional
          />
          <Button className="c-details-form__button c-details-form__button--submit" type="submit">{addNew ? 'Create' : 'Save'}</Button>
        </Form>
      </div>
      {changePassword && (
        <ChangePassword
          passwordChange={(newPassword) => setProfileFields(state => ({ ...state, password: newPassword }))}
          close={() => setChangePassword(false)}
          currentUser={currentUser}
        />
      )}
    </Modal>
  )
}

export default Profile;
