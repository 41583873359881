import firebase from './firebase';

import environment from '../environment/environment';

interface IRequest {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  headers: any,
  body?: any
}

const sendRequest = async (method: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string, body?: any) => {
  const token: any = await firebase.auth().currentUser?.getIdToken(true);

  const options: IRequest = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'token': token
    }
  };

  if (method === 'POST' || method === 'PUT') {
    options.body = JSON.stringify(body)
  }

  return fetch(environment.apiUrl + url, options).then(async res => {
    if (res.ok) {
      return res.json();
    }

    let error;
    try {
      error = await res.json();
    } catch (e) {
      error = res;
    }

    switch (error.statusCode) {
      case 400:
        error = {
          title: 'Bad request.',
          message: error.message
        }
        break;
      case 403:
        error = {
          title: 'Forbidden.',
          message: 'You are not currently an Administrator. Contact support@lightboxglobal.com for help.'
        }
        break;
      case 404:
        error = {
          title: 'Not found.',
          message: 'Not found.'
        }
        break;
      case 500:
        error = {
          title: 'Server error.',
          message: 'Internal server error'
        }
        break;
    }

    throw error;
  })
  //   .catch(err => { // TODO: Handle Connection refused
  //   console.log(!!err.match('Failed'));
  //   if (err.match('Failed to fetch')) {
  //     err = {
  //       title: 'Something went wrong.',
  //       message: 'Please, check your internet connection.'
  //     };
  //   }
  //
  //   throw err;
  // });
}

export default sendRequest;
