import { IProject, IProjectWithBasicInfo } from '../interfaces/project';

export const initialProject: IProject = {
  uuid: '',
  name: 'Unnamed Project',
  titlePhoto: '',
  bannerPhoto: '',
  description: '',
  managerEmail: '',
  projectManager: {
    firstName: '',
    lastName: '',
    email: ''
  },
  clientName: '',
  clientContactName: '',
  phone: '',
  email: '',
  creators: [],
  positions: [],
  applications: []
}

export const initialProjectWithBasicInfo: IProjectWithBasicInfo = {
  uuid: 'new-project',
  name: 'Unnamed Project',
  titlePhoto: '',
  status: '',
  hasNewApplications: false
}
