import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProtectedRoute({ children, ...rest }: any) {
  const { isAuthenticated } = useSelector((state: any) => state.userReducer);

  return (
    <Route
      {...rest}
      render={({ location }) => isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      )}
    />
  );
}


export default ProtectedRoute;
