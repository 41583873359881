import { ActionTypes } from './user.action';
import {IUser} from "../../interfaces/user";

const defaultState: any = {
  userRequestError: { type: '', title: '', message: '' },
  admins: [],
  creators: [],
  projectManagers: [],
  user: {},
  profile: {},
  projects: {}
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        isUserRequestInProgress: true,
        userRequestError: { type: '', title: '', message: '' }
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isUserRequestInProgress: false,
        isAuthenticated: true,
        user: action.payload.user
      };
    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isUserRequestInProgress: false,
        isAuthenticated: false,
        userRequestError: action.payload.userRequestError
      };
    case ActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isUserRequestInProgress: false,
        isAuthenticated: false,
        user: {}
      };
    case ActionTypes.VERIFY_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isUserRequestInProgress: false
      };
    case ActionTypes.PASSWORD_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        isUserRequestInProgress: false,
        isResetEmailWasSent: true
      }
    case ActionTypes.PASSWORD_RESET_EMAIL_ERROR:
    case ActionTypes.REQUEST_ERROR:
      return {
        ...state,
        isUserRequestInProgress: false,
        userRequestError: action.payload.userRequestError
      }
    case ActionTypes.RECEIVE_USERS_BY_ROLE:
      return {
        ...state,
        isUserRequestInProgress: false,
        admins: action.payload.role === 'admin' ? action.payload.users : state.admins,
        creators: action.payload.role === 'creator' ? action.payload.users : state.creators,
        projectManagers: action.payload.role === 'project_manager' ? action.payload.users : state.projectManagers
      }
    case ActionTypes.PROFILE_ACTION_REQUEST:
      return {
        ...state,
        profileAction: action.payload.profileAction,
        profile: action.payload.profile
      }
    case ActionTypes.DELETE_USER_SUCCESS: {
      const newManagers: IUser[] = state.projectManagers.filter((manager: IUser) => {
        return manager.uuid !== action.payload.deletedUserUuid;
      });
      const newCreators: IUser[] = state.creators.filter((creator: IUser) => {
        return creator.uuid !== action.payload.deletedUserUuid;
      });
      const newAdmins: IUser[] = state.admins.filter((admin: IUser) => {
        return admin.uuid !== action.payload.deletedUserUuid;
      });
      return {
        ...state,
        isUserRequestInProgress: false,
        projectManagers: newManagers,
        creators: newCreators,
        admins: newAdmins,
        userRequestSuccess: { action: action.type, success: true }
      }
    }
    case ActionTypes.CREATE_USER_SUCCESS: {
      const { user, role } = action.payload;
      if (role === 'admin') {
        return {
          ...state,
          isUserRequestInProgress: false,
          admins: [ ...state.admins, user ],
          userRequestSuccess: { action: action.type, success: true }
        }
      } else {
        return {
          ...state,
          isUserRequestInProgress: false,
          projectManagers: [ ...state.projectManagers, user ],
          userRequestSuccess: { action: action.type, success: true }
        }
      }
    }
    case ActionTypes.UPDATE_USER_SUCCESS: {
      const newManagers: IUser[] = state.projectManagers.map((manager: IUser) => {
        return manager.uuid === action.payload.uuid ? { ...manager, ...action.payload } : manager;
      });
      const newCreators: IUser[] = state.creators.map((creator: IUser) => {
        return creator.uuid === action.payload.uuid ? { ...creator, ...action.payload } : creator;
      });
      const newAdmins: IUser[] = state.admins.map((admin: IUser) => {
        return admin.uuid === action.payload.uuid ? { ...admin, ...action.payload } : admin;
      });
      const newUser: IUser = state.user.uuid === action.payload.uuid ?
          { ...state.user, ...action.payload } :
          state.user;
      return {
        ...state,
        isUserRequestInProgress: false,
        projectManagers: newManagers,
        creators: newCreators,
        admins: newAdmins,
        user: newUser,
        userRequestSuccess: { action: action.type, success: true }
      }
    }
    case ActionTypes.MAKE_ADMIN_SUCCESS: {
      const newManagers: IUser[] = state.projectManagers.filter((manager: IUser) => {
        return manager.uuid !== action.payload;
      });
      const newCreators: IUser[] = state.creators.filter((creator: IUser) => {
        return creator.uuid !== action.payload;
      });
      return {
        ...state,
        isUserRequestInProgress: false,
        projectManagers: newManagers,
        creators: newCreators,
        userRequestSuccess: { action: action.type, success: true }
      }
    }
    case ActionTypes.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        isUserRequestInProgress: false,
        userRequestSuccess: { action: action.type, success: true }
      }
    case ActionTypes.CLEAR_USER_REQUEST:
      return {
        ...state,
        userRequestSuccess: null
      }
    default:
      return state;
  }
};

export default userReducer;
