import React, { Dispatch, useEffect, useState } from 'react';
import './Home.scss';

import classnames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { Route, Switch, Link, useRouteMatch, useHistory } from 'react-router-dom';
import { Add32, CaretLeft32, CaretRight32 } from '@carbon/icons-react';
import { Loading, Button } from 'carbon-components-react';

import { getProjects, transferNewProjectName } from '../../store/project/project.action';
import { logoutUser, requestProfileAction } from '../../store/user/user.action';
import { getQueryParams } from '../../scripts/get-query-params';
import { initialProjectWithBasicInfo } from '../../constants/initial-project';
import { IProjectWithBasicInfo } from '../../interfaces/project';

import Logo from '../Logo';
import User from '../User';
import People from './People';
import Profile from '../Profile';
import Project from './Project';
import ConfirmationPopUp from '../ConfirmationPopUp';

type propTypes = {
  dispatch: Dispatch<any>
}

const initialRouteDetails = {
  route: '',
  menuItem: ''
}

function Home({ dispatch }: propTypes) {
  const history = useHistory();
  const { path, url } = useRouteMatch();

  const { projects, isProjectRequestInProgress, isProjectSaved } = useSelector((state: any) => state.projectReducer);
  const { user, isUserRequestInProgress } = useSelector((state: any) => state.userReducer);

  const [activeMenuItem, setActiveMenuItem] = useState('');
  const [nextRouteDetails, setNextRouteDetails] = useState({ ...initialRouteDetails });
  const [unsavedChangesMessage, setUnsavedChangesMessage] = useState('');
  const [projectsWithBasicInfo, setProjectsWithBasicInfo] = useState<IProjectWithBasicInfo[]>([])
  const [projectsPages, setProjectsPages] = useState<IProjectWithBasicInfo[][]>([]);
  const [projectsPageIndexes, setProjectsPageIndexes] = useState({ current: 0, last: 0 });

  useEffect(() => {
    dispatch(getProjects());

    const { queryLink, params } = getQueryParams(['projectUuid']);

    if (params.projectUuid) {
      history.push({
        pathname: `${url}/projects/${params.projectUuid}`,
        search: queryLink
      });
      setActiveMenuItem(params.projectUuid)
    } else {
      history.push(`${url}/people/project_managers`);
      setActiveMenuItem('project_managers');
    }
  }, []);

  useEffect(() => {
    setProjectsWithBasicInfo(projects);

    updateProjectPages(projects);
  }, [projects])

  useEffect(() => { // TODO: needs to be refactored
    dispatch(transferNewProjectName(activeMenuItem === 'new-project' ? activeMenuItem : ''));

    let newProjectsWithBasicInfo;
    if (activeMenuItem === 'new-project') {
      newProjectsWithBasicInfo = [...projectsWithBasicInfo];
      newProjectsWithBasicInfo.unshift(initialProjectWithBasicInfo);
    } else {
      newProjectsWithBasicInfo = projectsWithBasicInfo.filter(project => project.uuid !== 'new-project');
    }

    setProjectsWithBasicInfo(newProjectsWithBasicInfo);

    updateProjectPages(newProjectsWithBasicInfo);
  }, [activeMenuItem]);

  const editProfile = () => {
    dispatch(requestProfileAction(user, 'account'));
  }

  const logout = () => {
    dispatch(logoutUser());
  }

  const redirectTo = (e, route: string, menuItem: string) => {
    if (unsavedChangesMessage) {
      history.push(nextRouteDetails.route);
      setActiveMenuItem(nextRouteDetails.menuItem);
      closeUnsavedChangesPopUp();
    } else if (isProjectSaved) {
      setActiveMenuItem(menuItem);
    } else {
      e.preventDefault();
      setNextRouteDetails({ route, menuItem });
      setUnsavedChangesMessage('Your changes have not been saved. Please click the "Keep Editing" button to continue editing or the "Discard Changes" button to proceed without saving.');
    }
  }

  const closeUnsavedChangesPopUp = () => {
    setUnsavedChangesMessage('');
    setNextRouteDetails({ ...initialRouteDetails });
  }

  const updateProjectPages = (projects) => {
    if (projects.length <= 5) {
      setProjectsPages([projects]);
      setProjectsPageIndexes({ current: 0, last: 0 });
      return;
    }

    const pages: IProjectWithBasicInfo[][] = [[]];

    let pageIndex = 0;
    for (let i = 0; i < projects.length; i++) {
      if (i > 0 && i % 5 === 0) {
        pages.push([]);
        pageIndex++;
      }
      pages[pageIndex].push(projects[i])
    }

    setProjectsPageIndexes({
      current: activeMenuItem === 'new-project' ? 0 : projectsPageIndexes.current,
      last: pages.length - 1
    });

    setProjectsPages(pages);
  }

  const onNavigate = (dir) => {
    if (dir === 'left' && projectsPageIndexes.current) {
      setProjectsPageIndexes({
        ...projectsPageIndexes,
        current: projectsPageIndexes.current - 1
      });
    } else if (projectsPageIndexes.current !== projectsPageIndexes.last) {
      setProjectsPageIndexes({
        ...projectsPageIndexes,
        current: projectsPageIndexes.current + 1
      });
    }
  }

  return (
    <div className="c-home">
      <div className="c-menu">
        <Logo/>

        <div className="c-menu__block">
          <div className="c-menu__side-bar-title-wrapper">
            <h1 className="c-menu__title">Projects</h1>
            <Link
              to={`${url}/projects/new-project`}
              className="bx--overflow-menu--secondary bx--overflow-menu c-menu__item__link--plus"
              onClick={(e) => redirectTo(e, `${url}/projects/new-project`, 'new-project')}
            >
              <Add32/>
            </Link>
          </div>

          {isProjectRequestInProgress && !projectsWithBasicInfo.length && (
            <Loading small description="Active loading indicator" withOverlay={false}/>
          )}

          {!!projectsPages.length && projectsPages[projectsPageIndexes.current].map((project, index) => (
            <p className="c-menu__item" key={index}>
              <Link to={`${url}/projects/${project.uuid}`}
                    className={
                      classnames({
                        'c-menu__item__link': true,
                        'c-menu__item__link--active': activeMenuItem === project.uuid
                      })
                    }
                    onClick={(e) => redirectTo(e, `${url}/projects/${project.uuid}`, project.uuid)}
              >
                {project.hasNewApplications ? (
                  <>{project.name}<span className="bx--indication"></span></>
                ) : project.name}
              </Link>
            </p>
          ))}

          {!!projectsPages.length && !!projectsPageIndexes.last && (
            <div className="c-menu__pagination">
              <p className="c-menu__pagination-pages">
                Page {projectsPageIndexes.current + 1} of {projectsPageIndexes.last + 1}
              </p>
              <Button
                type="button"
                kind="secondary"
                disabled={!projectsPageIndexes.current}
                onClick={() => onNavigate('left')}
              >
                <CaretLeft32/>
              </Button>
              <Button
                type="button"
                kind="secondary"
                disabled={projectsPageIndexes.current === projectsPageIndexes.last}
                onClick={() => onNavigate('right')}
              >
                <CaretRight32/>
              </Button>
            </div>
          )}
        </div>

        <div className="c-menu__block">
          <h1 className="c-menu__title">People</h1>
          {user.role === 'admin' && (
            <p className="c-menu__item">
              <Link to={`${url}/people/admins`}
                    className={
                      classnames({
                        'c-menu__item__link': true,
                        'c-menu__item__link--active': activeMenuItem === 'admins'
                      })
                    }
                    onClick={(e) => redirectTo(e, `${url}/people/admins`, 'admins')}
              >
                Admins
              </Link>
            </p>
          )}
          <p className="c-menu__item">
            <Link to={`${url}/people/creators`}
                  className={
                    classnames({
                      'c-menu__item__link': true,
                      'c-menu__item__link--active': activeMenuItem === 'creators'
                    })
                  }
                  onClick={(e) => redirectTo(e, `${url}/people/creators`, 'creators')}
            >
              Creators
            </Link>
          </p>
          <p className="c-menu__item">
            <Link to={`${url}/people/project_managers`}
                  className={
                    classnames({
                      'c-menu__item__link': true,
                      'c-menu__item__link--active': activeMenuItem === 'project_managers'
                    })
                  }
                  onClick={(e) => redirectTo(e, `${url}/people/project_managers`, 'project_managers')}
            >
              Project Managers
            </Link>
          </p>
        </div>

        <div className="c-account">
          <User user={user}
                buttons={['Sign Out', 'Account']}
                handleSuccess={editProfile}
                handleError={logout}
                isAccount
                canManage
          />
        </div>
      </div>


      <div className="c-main">
        {(isProjectRequestInProgress || isUserRequestInProgress) && <Loading className={'c-main__loading'}/>}

        <Switch>
          <Route path={`${path}/people/:roleGroup`}>
            <People/>
          </Route>
          <Route path={`${path}/projects/:projectId`}>
            <Project/>
          </Route>
        </Switch>
      </div>

      <Profile isAdmin={activeMenuItem === 'admins'} />

      <ConfirmationPopUp title="Unsaved changes"
                         message={unsavedChangesMessage}
                         handler={redirectTo}
                         close={closeUnsavedChangesPopUp}
                         buttons={['Keep Editing', 'Discard Changes']}
      />
    </div>
  );
}

export default connect()(Home);
