import React, {useEffect, useState} from 'react';

import './ChangePassword.scss';
import {Button, Form, Loading} from 'carbon-components-react';
import InputField from '../../InputField';
import {useDispatch, useSelector} from "react-redux";
import {ActionTypes, changePassword, userClearRequest} from "../../../store/user/user.action";

type propTypes = {
  currentUser?: boolean,
  passwordChange: Function,
  close: Function
}

const ChangePassword = ({ passwordChange, close, currentUser }: propTypes) => {
  const {userRequestSuccess, isUserRequestInProgress} = useSelector((state: any) => state.userReducer);
  const dispatch = useDispatch();
  const [passwordFields, setPasswordFields] = useState({ password: '', newPassword: '', repeatNewPassword: '' });
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (
        userRequestSuccess?.success &&
        userRequestSuccess.action === ActionTypes.PASSWORD_CHANGE_SUCCESS
    ) {
      dispatch(userClearRequest());
      close();
    }
  }, [userRequestSuccess]);

  const handleChange = (field: string, value: string): void => {
    setPasswordFields({
      ...passwordFields,
      [field]: value
    });
  };

  const countErrors = (action: string, field: string) => {
    if (action === 'add' && !errors.includes(field)) {
      setErrors([...errors, field]);
    }

    if (action === 'remove' && errors.includes(field)) {
      const newErrors = errors.filter(error => error !== field);
      setErrors(newErrors);
    }
  }

  const submit = (e): void => {
    e.preventDefault();

    if (errors.length) {
      setShowErrors(true);
      return;
    }

    setShowErrors(true);

    if (currentUser) {
      dispatch(changePassword(passwordFields));
    } else {
      passwordChange(passwordFields.newPassword);
      close();
    }
  };

  return (
    <div className="c-profile">
      {isUserRequestInProgress && <Loading className={'c-main__loading'}/>}
      <p className="modal-title m-bottom-40">Change password</p>
      <Form onSubmit={e => submit(e)}>
        <div hidden={!currentUser}>
          <InputField type="textInput"
                      label="Old Password"
                      placeholder="Password"
                      id="profile-old-password"
                      name="password"
                      inputType="password"
                      value={passwordFields.password}
                      showErrors={showErrors}
                      handleChange={handleChange}
                      countErrors={countErrors}
          />
        </div>
        <InputField type="textInput"
                    label="New Password"
                    placeholder="Password"
                    id="profile-new-password"
                    name="newPassword"
                    inputType="password"
                    value={passwordFields.newPassword}
                    showErrors={showErrors}
                    handleChange={handleChange}
                    countErrors={countErrors}
        />
        <div className="m-bottom-25">
          <InputField type="textInput"
                      label="New Password, again"
                      placeholder="Password"
                      id="profile-repeat-new-password"
                      name="repeatNewPassword"
                      inputType="password"
                      value={passwordFields.repeatNewPassword}
                      handlePasswords={passwordFields}
                      showErrors={showErrors}
                      handleChange={handleChange}
                      countErrors={countErrors}
          />
        </div>

        <div className="l-btn-wrap">
          <Button className="c-details-form__button" type="submit">Save</Button>
          <Button kind="secondary" className="c-details-form__button" type="button" onClick={close}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
}

export default ChangePassword;
