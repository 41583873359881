import React, { Dispatch, useState } from 'react';
import './Positions.scss'

import { connect } from 'react-redux';
import { Button } from 'carbon-components-react';

import { deletePosition } from '../../../../store/project/project.action';
import { IPosition } from '../../../../interfaces/position';
import { IProject } from '../../../../interfaces/project';
import { initialPosition } from '../../../../constants/initial-position';

import ConfirmationPopUp from '../../../ConfirmationPopUp';
import ManagePosition from './ManagePosition';
import Position from './Position';

type propTypes = {
  dispatch: Dispatch<any>,
  project: IProject
}

function Positions({ project, dispatch }: propTypes) {
  const [isOpened, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState<'Add' | 'Edit'>('Add');
  const [currentPosition, setCurrentPosition] = useState<IPosition>({ ...initialPosition });
  const [positionForDeletion, setPositionForDeletion] = useState<IPosition | null>(null);
  const [confirmMessage, setConfirmMessage] = useState('');

  const openModal = (action: 'Add' | 'Edit') => {
    setOpenModal(true);
    setModalAction(action);
  }

  const closeModal = () => {
    setOpenModal(false);
    setCurrentPosition(initialPosition);
  }

  const removePosition = (position: IPosition) => {
    if (confirmMessage) {
      dispatch(deletePosition(positionForDeletion, project.uuid, positionForDeletion?.uuid));
      closeConfirmationPopUp();
    } else {
      setPositionForDeletion(position);
      setConfirmMessage('Are you sure you want to delete this position? All applications related to this position will be politely passed and team members will be removed.');
    }
  }

  const closeConfirmationPopUp = () => {
    setPositionForDeletion(null);
    setConfirmMessage('');
  }

  return (
    <div className="c-positions">
      <ManagePosition
        isOpened={isOpened}
        action={modalAction}
        close={closeModal}
        projectId={project.uuid}
        currentPosition={currentPosition}
      />

      <Button className="c-details-form__button m-bottom-40" type="button" onClick={() => openModal('Add')}>
        Add Position
      </Button>

      <div className="positions-wrapper">
        {project.positions?.length && project.positions.map((position, index) => (
          <div className="c-details-form m-bottom-40" key={index}>
            <Position
              position={position}
              setCurrentPosition={setCurrentPosition}
              openPositionModal={openModal}
              removePosition={removePosition}
            />
          </div>
        ))}
      </div>

      <ConfirmationPopUp title={'Delete position'}
                         message={confirmMessage}
                         handler={removePosition}
                         close={closeConfirmationPopUp}
                         buttons={['Cancel', 'Delete']}
      />
    </div>
  )
}

export default connect()(Positions);
