import {ActionTypes} from "./project.action";

const defaultState = {
    projectRequestError: {type: '', title: '', message: ''},
    projects: {},
    project: {},
    isProjectRequestInProgress: true,
    createdProject: {},
    newProjectName: '',
    isProjectSaved: true
};

const projectReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.RECEIVE_PROJECTS:
            return {
                ...state,
                projects: action.payload.projects,
                isProjectRequestInProgress: false
            }
        case ActionTypes.RECEIVE_SINGLE_PROJECTS:
            return {
                ...state,
                project: action.payload.project,
                isProjectRequestInProgress: false
            }
        case ActionTypes.REQUEST_PROJECT:
            return {
                ...state,
                isProjectRequestInProgress: true
            }
        case ActionTypes.RECEIVE_CREATED_PROJECT:
            return {
                ...state,
                createdProject: action.payload.createdProject,
            }
        case ActionTypes.TRANSFER_NEW_PROJECT_NAME:
            return {
                ...state,
                newProjectName: action.payload.newProjectName,
            }
        case ActionTypes.REQUEST_ERROR:
            return {
                ...state,
                isProjectRequestInProgress: false,
                projectRequestError: action.payload.projectRequestError
            }
        case ActionTypes.REQUEST_SUCCESS:
            return {
                ...state,
                isProjectRequestInProgress: false
            }
        case ActionTypes.PROJECT_SAVED_STATUS:
            return {
                ...state,
                isProjectSaved: action.payload.isProjectSaved
            }
        default:
            return state;
    }
};

export default projectReducer;
