import React, { Dispatch, useState } from 'react';
import './Team.scss'

import { connect, useSelector } from 'react-redux';
import classnames from 'classnames';

import {approveCreator, deleteApplication, getProjects} from '../../../../store/project/project.action';
import { IUser } from '../../../../interfaces/user';
import { IProject } from '../../../../interfaces/project';
import { initialConfirmData } from '../../../../constants/initial-confirm-data';

import User from '../../../User';
import ConfirmationPopUp from '../../../ConfirmationPopUp';

type propTypes = {
  project: IProject,
  dispatch: Dispatch<any>
}

function Team({ project, dispatch }: propTypes) {
  const { isProjectRequestInProgress } = useSelector((state: any) => state.projectReducer);

  const [confirmData, setConfirmData] = useState({ ...initialConfirmData });
  const [applicationUser, setApplicationUser] = useState<IUser | null>();

  const getApplication = (user) => {
    return project.applications.find(application => application.user === user);
  }

  const approveApplication = (user: IUser) => {
    const application = getApplication(user);

    if (application) {
      dispatch(approveCreator(project.uuid, application.uuid));
    }
  }

  const removeApplication = (user: IUser) => {
    const application = getApplication(confirmData.message ? applicationUser : user);

    if (confirmData.message) {
      dispatch(deleteApplication(project.uuid, application ? application.uuid : applicationUser?.applicationUuid));
      cancelDeleteAction();
    } else if (user) {
      setApplicationUser(user);
      openConfirmationPoUp(!!application, user);
    }
  }

  const cancelDeleteAction = () => {
    setApplicationUser(null);
    setConfirmData({ ...initialConfirmData });
  }

  const openConfirmationPoUp = (isApplication: boolean, user: IUser) => {
    if (isApplication) {
      setConfirmData({
        title: 'Politely pass application',
        message: `Are you sure you want to politely pass on ${user.firstName} ${user.lastName}'s application?`,
        buttons: ['Cancel', 'Politely Pass']
      });
    } else {
      setConfirmData({
        title: 'Remove team member',
        message: `Are you sure you want to remove ${user.firstName} ${user.lastName} from the team?`,
        buttons: ['Cancel', 'Remove']
      });
    }
  }

  return (
    <div className="c-team">
      {!project.creators?.length && !project.applications?.length && !isProjectRequestInProgress && (
        <p className="c-team__description">
          There are currently no team members within this project. Please invite people who may be interested to apply.
        </p>
      )}

      <div className={classnames('c-team__block', { 'c-team__block--empty': !project.applications?.length })}>
        <h2 className="section-title">Pending Creators</h2>

        <div className="c-people__main">
          {project.applications && project.applications.map((application, index) => (
            <User key={index}
                  user={application.user}
                  buttons={['Politely Pass', 'Approve']}
                  handleSuccess={approveApplication}
                  handleError={removeApplication}
                  userRole="creators"
                  canManage
                  indication
            />
          ))}
        </div>
      </div>

      {!!project.creators?.length && !!project.applications?.length && <hr className="c-team__line-break"/>}

      <div className={classnames('c-team__block', { 'c-team__block--empty': !project.creators?.length })}>
        <h2 className="section-title">Approved Creators</h2>

        <div className="c-people__main">
          {project.creators && project.creators.map((user, index) => (
            <User key={index}
                  user={user}
                  buttons={['Remove']}
                  handleError={removeApplication}
                  userRole="creators"
                  canManage
            />
          ))}
        </div>
      </div>

      <ConfirmationPopUp {...confirmData}
                         handler={removeApplication}
                         close={cancelDeleteAction}
      />
    </div>
  )
}

export default connect()(Team);
