import React, { Dispatch, useEffect, useState } from 'react';
import './People.scss';

import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, TextInput } from 'carbon-components-react';

import {
  deleteUserRequest,
  getUsersByRole,
  requestProfileAction,
  updateUserRequest
} from '../../../store/user/user.action';
import { IUser } from '../../../interfaces/user';

import User from '../../User/User';
import ConfirmationPopUp from '../../ConfirmationPopUp';

type propTypes = {
  dispatch: Dispatch<any>
}

function People({ dispatch }: propTypes) {
  const { roleGroup } = useParams();
  const { creators, projectManagers, admins, deletedUserUuid, isUserRequestInProgress, user: loggedUser } = useSelector((state: any) => state.userReducer);

  const [title, setTitle] = useState('');
  const [users, setUsers] = useState<IUser[]>([]);
  const [scrollTop, setScrollTop] = useState(0);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [userForDeletion, setUserForDeletion] = useState<IUser | null>();

  useEffect(() => {
    setTitle(roleGroup.replace('_', ' '));

    if (roleGroup === 'creators') {
      dispatch(getUsersByRole('creator'));
    }

    if (roleGroup === 'project_managers') {
      dispatch(getUsersByRole('project_manager'));
    }

    if (roleGroup === 'admins') {
      dispatch(getUsersByRole('admin'));
    }

    updateUsers();
  }, [roleGroup]);

  useEffect(() => {
    updateUsers();
  }, [admins, creators, projectManagers]);

  const updateUsers = () => {
    if (roleGroup === 'creators') {
      setUsers(creators);
    }

    if (roleGroup === 'project_managers') {
      setUsers(projectManagers);
    }

    if (roleGroup === 'admins') {
      setUsers(admins);
    }
  }

  const editUserProfile = (user) => {
    dispatch(requestProfileAction(user, 'edit'));
  }

  const makeAdmin = (user) => {
    dispatch(updateUserRequest({ ...user, uuid: user.uuid }, true));
    updateUsers();
  }

  const deleteUser = (user) => {
    if (confirmMessage) {
      dispatch(deleteUserRequest(userForDeletion));
      setConfirmMessage('');
    } else if (user) {
      setUserForDeletion(user);
      setConfirmMessage(`Are you sure you want to delete ${user.firstName} ${user.lastName}'s account?`);
    }
  }

  const cancelDeleteUserAction = () => {
    setUserForDeletion(null);
    setConfirmMessage('');
  }

  const addProjectManager = () => {
    dispatch(requestProfileAction(null, 'add'));
  }

  const searchUser = value => {
    let foundUsers;
    let allUsers = roleGroup === 'admins' ? admins : roleGroup === 'project_managers' ? projectManagers : creators;
    const regExp = new RegExp(value, 'i');

    foundUsers = allUsers.filter(user => {
      return regExp.test(user.firstName) || regExp.test(user.lastName) || regExp.test(user.email) ||
          regExp.test(user.primarySkills) || regExp.test(user.secondarySkills);
    });

    console.log(allUsers);

    if (value) {
      setUsers(foundUsers);
    } else {
      updateUsers();
    }
  }

  return (
    <div className="c-people">
      <h1 className="c-title">{title}</h1>

      {(roleGroup === 'project_managers' || roleGroup === 'admins') && loggedUser.role === 'admin' ? (
        <Button className="c-people__add-btn bx--btn--primary" onClick={() => addProjectManager()}>Add</Button>
      ) : (
        <div className="c-people__search">
          <TextInput labelText=""
                     placeholder="Search"
                     id="search"
                     type="text"
                     className="c-people__search__input"
                     onChange={e => searchUser(e.target.value)}
          />
        </div>
      )}

      <div className="c-people__main-wrapper" onScroll={(e: any) => setScrollTop(e.target.scrollTop)}>
        <div className="c-people__main">
          {users.length && users.map((user, index) => (
            <User key={index}
                  user={user}
                  buttons={['Delete', 'Edit', 'Make Admin']}
                  handleSuccess={editUserProfile}
                  handleMakeAdmin={makeAdmin}
                  handleError={deleteUser}
                  userRole={roleGroup}
                  scrollTop={scrollTop}
                  canManage={loggedUser.role === 'admin'}
            />
          ))}

          {(!users.length && roleGroup === 'creators' && !isUserRequestInProgress) && (
            <p className="c-people__description">
              Please create a project and invite creators to join. The creators who apply and you have approved will
              appear here.
            </p>
          )}
        </div>
      </div>

      <ConfirmationPopUp title={'Delete user'}
                         message={confirmMessage}
                         handler={deleteUser}
                         close={cancelDeleteUserAction}
                         buttons={['Cancel', 'Delete']}
      />
    </div>
  );
}

export default connect()(People);
