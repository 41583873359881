import React, { Dispatch, useEffect, useState } from 'react';
import './Details.scss'

import { connect, useSelector } from 'react-redux';
import Autocomplete from 'react-autocomplete';
import { Button, Form } from 'carbon-components-react';
import classnames from 'classnames';

import {
  editDetailsAction,
  saveDetailsAction,
  transferNewProjectName,
  updateProjectSavedStatus
} from '../../../../store/project/project.action';
import { getUsersByRole } from '../../../../store/user/user.action';

import { IProject } from '../../../../interfaces/project';
import { initialProject } from '../../../../constants/initial-project';
import { areObjectsTheSame } from '../../../../scripts/compare-objects';

import ImageUploader from '../../../ImageUploader';
import InputField from '../../../InputField';

type propTypes = {
  dispatch: Dispatch<any>,
  project: IProject
}

function Details({ project, dispatch }: propTypes) {
  const { projectManagers, admins } = useSelector((state: any) => state.userReducer);

  const [details, setDetails] = useState<IProject>({ ...initialProject });
  const [isDetailsSaved, setDetailsSavedValue] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [managerName, setManagerName] = useState('');

  const managers = [...projectManagers, ...admins];

  const autoCompleteMenuStyle = {
    borderRadius: '6px',
    padding: '2px 0',
    overflow: 'auto',
    maxHeight: '400px',
    zIndex: '999'
  };

  useEffect(() => {
    dispatch(getUsersByRole('project_manager'));
    dispatch(getUsersByRole('admin'));
  }, []);

  useEffect(() => {
    setDetails(project);
    setShowErrors(false);
    if (project.uuid) {
      setErrors([]);
      setManagerName(`${project.projectManager?.firstName} ${project.projectManager?.lastName}`);
    } else {
      setErrors(['projectManager']);
      setManagerName('');
      dispatch(transferNewProjectName(project.name));
    }
  }, [project]);

  useEffect(() => {
    const isSaved = areObjectsTheSame(details, project);
    dispatch(updateProjectSavedStatus(isSaved));
    setDetailsSavedValue(isSaved);
  }, [details]);

  const findManagerData = (name) => {
    setManagerName(name);

    const firstName = name.split(' ').slice(0, -1).join(' ');
    const lastName = name.split(' ').slice(-1).join(' ');

    const projectManager = managers.find(projectManager => projectManager.firstName === firstName && projectManager.lastName === lastName);

    if (projectManager) {
      setDetails({
        ...details,
        managerEmail: projectManager.email
      });

      countErrors('remove', 'projectManager');
    } else {
      countErrors('add', 'projectManager');
    }
  };

  const submit = (e): void => {
    e.preventDefault();

    if (errors.length) {
      setShowErrors(true);
      return;
    }

    if (details.uuid) {
      dispatch(editDetailsAction(details));
    } else {
      dispatch(saveDetailsAction(details));
    }
  };

  const handleChange = (field: string, value: string): void => {
    setDetails({ ...details, [field]: value });

    if (field === 'name' && !details.uuid) {
      dispatch(transferNewProjectName(value));
    }
  };

  const countErrors = (action: string, field: string) => {
    if (action === 'add' && !errors.includes(field)) {
      setErrors([...errors, field]);
    }

    if (action === 'remove' && errors.includes(field)) {
      const newErrors = errors.filter(error => error !== field);
      setErrors(newErrors);
    }
  }

  return (
    <Form className="c-details-form" onSubmit={e => submit(e)}>
      <div className="fields-wrapper__two-columns">
        <div className="fields-part">
          <InputField type="textInput"
                      label="Project Name"
                      placeholder="Enter project name"
                      id="name"
                      name="name"
                      inputType="text"
                      value={details.name}
                      showErrors={showErrors}
                      handleChange={handleChange}
                      countErrors={countErrors}
          />

          <InputField type="textInput"
                      label="Client Contact Name"
                      placeholder="Enter client contact name"
                      id="client-contact-name"
                      name="clientContactName"
                      inputType="text"
                      value={details.clientContactName}
                      showErrors={showErrors}
                      handleChange={handleChange}
                      countErrors={countErrors}
          />

          <div className="m-bottom-40">
            <InputField type="textInput"
                        label="Email"
                        placeholder="Enter email"
                        id="email"
                        name="email"
                        inputType="email"
                        value={details.email}
                        showErrors={showErrors}
                        handleChange={handleChange}
                        countErrors={countErrors}
            />
          </div>

          <div className="autocomplete-wrapper">
            <p className="bx--file--label">Lightbox Project Manager</p>
            <div className={classnames({ 'border-red': errors.includes('projectManager') && showErrors })}>
              <Autocomplete
                getItemValue={projectManager => `${projectManager.firstName} ${projectManager.lastName}`}
                items={managers}
                shouldItemRender={(projectManager, value) => projectManager.firstName.toLowerCase().indexOf(value.toLowerCase()) > -1}
                renderItem={(projectManager, isHighlighted) => (
                  <div className={classnames('autocomplete-item', { 'autocomplete-item--highlighted': isHighlighted })}
                       key={projectManager.uuid}
                  >
                    {projectManager.firstName} {projectManager.lastName}
                  </div>
                )}
                value={managerName}
                onChange={e => findManagerData(e.target.value)}
                onSelect={value => findManagerData(value)}
                wrapperStyle={null}
                menuStyle={autoCompleteMenuStyle}
              />
            </div>

            {errors.includes('projectManager') && showErrors && (
              <p className="error-text" id="title-photo-error-msg">This field should not be empty.</p>
            )}
          </div>
        </div>

        <div className="fields-part">
          <InputField type="textInput"
                      label="Client Name"
                      placeholder="Enter client name"
                      id="client-name"
                      name="clientName"
                      inputType="text"
                      value={details.clientName}
                      showErrors={showErrors}
                      handleChange={handleChange}
                      countErrors={countErrors}
          />

          <InputField type="textInput"
                      label="Phone Number"
                      placeholder="Enter phone number"
                      id="phone-number"
                      name="phone"
                      inputType="number"
                      value={details.phone}
                      showErrors={showErrors}
                      handleChange={handleChange}
                      countErrors={countErrors}
          />
        </div>
      </div>

      <InputField type="textArea"
                  label="Description"
                  placeholder="Enter description"
                  rows={5}
                  id="description"
                  name="description"
                  inputType="text"
                  value={details.description}
                  showErrors={showErrors}
                  handleChange={handleChange}
                  countErrors={countErrors}
      />

      <div className="fields-wrapper__two-columns m-bottom-40">
        <div className="fields-part">
          <ImageUploader type="titlePhoto"
                         resolution="188x280"
                         image={details.titlePhoto}
                         title="Title Photo"
                         showErrors={showErrors}
                         handleChange={handleChange}
                         countErrors={countErrors}
          />
        </div>
        <div className="fields-part">
          <ImageUploader type="bannerPhoto"
                         resolution="1200x375"
                         image={details.bannerPhoto}
                         title="Banner Photo"
                         showErrors={showErrors}
                         handleChange={handleChange}
                         countErrors={countErrors}
          />
        </div>
      </div>

      <div className="button-wrapper">
        <Button className="c-details-form__button" type="submit" disabled={isDetailsSaved}>Save</Button>
      </div>
    </Form>
  )
}

export default connect()(Details);
