import sendRequest from '../../services/request';
import { IPosition } from '../../interfaces/position';

export enum ActionTypes {
  RECEIVE_PROJECTS = 'RECEIVE_PROJECTS',
  RECEIVE_SINGLE_PROJECTS = 'RECEIVE_SINGLE_PROJECTS',
  REQUEST_PROJECT = 'REQUEST_PROJECT',
  RECEIVE_CREATED_PROJECT = 'RECEIVE_CREATED_PROJECT',
  TRANSFER_NEW_PROJECT_NAME = 'TRANSFER_NEW_PROJECT_NAME',
  REQUEST_ERROR = 'REQUEST_ERROR',
  REQUEST_SUCCESS = 'REQUEST_SUCCESS',
  PROJECT_SAVED_STATUS = 'PROJECT_SAVED_STATUS'
}

const receiveProjects = (projects: any) => {
  return {
    type: ActionTypes.RECEIVE_PROJECTS,
    payload: { projects }
  }
}

export const request = () => {
  return {
    type: ActionTypes.REQUEST_PROJECT
  };
};

export const requestSuccess = () => {
  return {
    type: ActionTypes.REQUEST_SUCCESS
  };
};


export const transferNewProjectName = (newProjectName) => {
  return {
    type: ActionTypes.TRANSFER_NEW_PROJECT_NAME,
    payload: { newProjectName }
  }
}

export const requestError = (type: string, error: {title: string, message: string}) => {
  return {
    type: ActionTypes.REQUEST_ERROR,
    payload: {
      projectRequestError: { type: type || 'other', ...error }
    }
  }
}

export const receiveSingleProject = (project: any) => {
  return {
    type: ActionTypes.RECEIVE_SINGLE_PROJECTS,
    payload: { project }
  }
}

export const getCreatedProject = (createdProject: any) => {
  return {
    type: ActionTypes.RECEIVE_CREATED_PROJECT,
    payload: { createdProject }
  }
}

export const updateProjectSavedStatus = (status: boolean) => {
  return {
    type: ActionTypes.PROJECT_SAVED_STATUS,
    payload: {
      isProjectSaved: status
    }
  }
}

export const editDetailsAction = (details: any) => async dispatch => {
  dispatch(request());

  const body = {
    project: {
      name: details.name,
      titlePhoto: details.titlePhoto,
      bannerPhoto: details.bannerPhoto,
      description: details.description,
      clientName: details.clientName,
      clientContactName: details.clientContactName,
      phone: details.phone,
      email: details.email,
      managerEmail: details.managerEmail
    }
  };

  sendRequest('PUT', '/api/projects/' + details.uuid, body)
    .then(() => {
      dispatch(getProjects());
      dispatch(requestProjectAction(details.uuid));
    })
    .catch(err => {
      dispatch(requestError('other', err));
    });
}

export const saveDetailsAction = (submitData: any) => async dispatch => {
  dispatch(request());

  const body = {
    project: {
      name: submitData.name,
      titlePhoto: submitData.titlePhoto,
      bannerPhoto: submitData.bannerPhoto,
      description: submitData.description,
      clientName: submitData.clientName,
      clientContactName: submitData.clientContactName,
      phone: submitData.phone,
      email: submitData.email,
      managerEmail: submitData.managerEmail
    }
  };

  sendRequest('POST', '/api/projects', body)
    .then(projects => {
      dispatch(getProjects());
      dispatch(requestProjectAction(projects.uuid));
      dispatch(getCreatedProject(projects));
      dispatch(transferNewProjectName(''));
    })
    .catch(err => {
      dispatch(requestError('other', err));
    });
}

export const requestProjectAction = (projectId: string) => async dispatch => {
  dispatch(request());

  sendRequest('GET', '/api/projects/' + projectId)
    .then(project => {
      dispatch(receiveSingleProject(project));
    })
    .catch(err => {
      dispatch(requestError('other', err));
    });
}

export const getProjects = () => async (dispatch) => {
  dispatch(request());

  sendRequest('GET', '/api/projects?amount=0')
    .then((projects) => {
      dispatch(receiveProjects(projects));
    })
    .catch(err => {
      dispatch(requestError('other', err));
    });
}

export const createPosition = (submitData: any, projectId: string) => async (dispatch) => {
  dispatch(request());

  const body = {
    position: {
      name: submitData.name,
      description: submitData.description
    }
  }

  sendRequest('POST', `/api/projects/${projectId}/position`, body)
    .then(() => {
      dispatch(requestProjectAction(projectId));
    })
    .catch(err => {
      dispatch(requestError('other', err));
    });
}

export const editPosition = (position: IPosition, projectId: string) => async (dispatch) => {
  dispatch(request());

  const body = {
    position: {
      name: position.name,
      description: position.description
    }
  };

  sendRequest('PUT', `/api/projects/${projectId}/position/${position.uuid}`, body)
    .then(() => {
      dispatch(requestProjectAction(projectId));
    })
    .catch(err => {
      dispatch(requestError('other', err));
    });
}

export const deletePosition = (submitData: any, projectId: string, positionId) => async (dispatch) => {
  dispatch(request());

  sendRequest('DELETE', `/api/projects/${projectId}/position/${positionId}`)
    .then(() => {
      dispatch(requestProjectAction(projectId));
    })
    .catch(err => {
      dispatch(requestError('other', err));
    });
}

export const approveCreator = (projectId: any, applicationId: any) => async (dispatch) => {
  dispatch(request());

  sendRequest('PUT', `/api/projects/${projectId}/application/${applicationId}`)
    .then(() => {
      dispatch(requestProjectAction(projectId));
      dispatch(getProjects());
    })
    .catch(err => {
      dispatch(requestError('other', err));
    });
};

export const deleteApplication = (projectId: string, applicationId: string) => async (dispatch) => {
  dispatch(request());

  sendRequest('DELETE', `/api/projects/${projectId}/application/${applicationId}`)
    .then(() => {
      dispatch(requestProjectAction(projectId));
    })
    .catch(err => {
      dispatch(requestError('other', err));
    });
};
