import React, { Dispatch, useEffect, useState } from 'react';
import './App.scss';

import { Redirect, Route, Switch } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { InlineNotification } from 'carbon-components-react';

import { verifyAuth } from './store/user/user.action';

import Auth from './components/Auth';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './components/Home';

type propTypes = {
  dispatch: Dispatch<any>
}

const initialError = {
  type: '',
  title: '',
  message: ''
}

function App({ dispatch }: propTypes) {
  const { userRequestError } = useSelector((state: any) => state.userReducer);
  const { projectRequestError } = useSelector((state: any) => state.projectReducer);

  const [error, setError] = useState({ ...initialError });

  useEffect(() => {
    dispatch(verifyAuth());
  }, []);

  useEffect(() => {
    setError(userRequestError);
  }, [userRequestError]);

  useEffect(() => {
    setError(projectRequestError);
  }, [projectRequestError]);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <Redirect to={'/login'}/>
        </Route>
        <Route path="/login">
          <Auth/>
        </Route>
        <ProtectedRoute path="/home">
          <Home/>
        </ProtectedRoute>
      </Switch>

      {error?.type === 'other' && (
        <div className="c-error-notification">
          <InlineNotification lowContrast
                              kind="error"
                              subtitle={<span>{error.message}</span>}
                              onCloseButtonClick={() => setError({ ...initialError })}
                              title={error.title}
          />
        </div>
      )}
    </div>
  );
}

export default connect()(App);
