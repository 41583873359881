import React, { useEffect, useState } from 'react';

import { OverflowMenu, OverflowMenuItem, TextInput, TextArea } from 'carbon-components-react';

import { IPosition } from '../../../../../interfaces/position';

type propTypes = {
  position: IPosition,
  setCurrentPosition: Function,
  openPositionModal: Function,
  removePosition: Function,
  scrollTop?: number
}

function Position({ position, setCurrentPosition, openPositionModal, removePosition, scrollTop }: propTypes) {
  const [isOptionsOpened, setOptionsOpenedValue] = useState(false);

  useEffect(() => {
    setOptionsOpenedValue(false);
  }, [scrollTop]);

  const handleSuccess = () => {
    setCurrentPosition(position);
    openPositionModal('Edit');
  }

  const handleClose = () => {
    removePosition(position);
  }

  return (
    <div className="c-position">
      <div className="position-title-menu">
        <TextInput labelText="Position Title"
                   value={position.name}
                   id="position-name"
                   type="text"
                   className="text-input__dark-bg disabled-input"
                   disabled
        />
        <div className="c-user__button-wrapper">
          <OverflowMenu direction={'bottom'}
                        flipped
                        iconClass={'c-user__options-btn'}
                        menuOptionsClass={'bx--overflow-menu-options--primary'}
                        className={'bx--overflow-menu--primary'}
                        open={isOptionsOpened}
                        onClick={() => setOptionsOpenedValue(!isOptionsOpened)}
          >
            <OverflowMenuItem className="c-user__options-btn__text--secondary"
                              itemText="Edit"
                              onClick={() => handleSuccess()}
            />
            <OverflowMenuItem className="c-user__options-btn__text--error"
                              itemText="Delete"
                              onClick={() => handleClose()}
            />
          </OverflowMenu>

        </div>
      </div>

      <TextArea labelText="Job Description"
                placeholder="Enter description"
                autoComplete="description"
                id="position-description"
                value={position.description}
                rows={10}
                className="text-input__dark-bg disabled-input"
                disabled
      />
    </div>
  );
}

export default Position;
