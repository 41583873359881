import React, { Dispatch, useEffect, useState } from 'react';
import './ManagePosition.scss';

import { connect } from 'react-redux';
import { Button, Form, Modal } from 'carbon-components-react';

import { createPosition, editPosition } from '../../../../../store/project/project.action';

import { IPosition } from '../../../../../interfaces/position';
import { initialPosition } from '../../../../../constants/initial-position';
import { areObjectsTheSame } from '../../../../../scripts/compare-objects';

import Logo from '../../../../Logo';
import InputField from '../../../../InputField';

type propTypes = {
  isOpened: boolean,
  action: 'Add' | 'Edit',
  close: Function,
  projectId: string,
  currentPosition: IPosition,
  dispatch: Dispatch<any>
}

function ManagePosition({ isOpened, action, close, projectId, currentPosition, dispatch }: propTypes) {
  const [position, setPosition] = useState<IPosition>({ ...initialPosition });
  const [isPositionSaved, setPositionSavedValue] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    setShowErrors(false);
    if (isOpened) {
      setPosition(currentPosition);
    }
  }, [isOpened]);

  useEffect(() => {
    setPositionSavedValue(areObjectsTheSame(position, currentPosition));
  }, [position]);

  const handleChange = (field: string, value: string): void => {
    setPosition({
      ...position,
      [field]: value
    });
  };

  const countErrors = (action: string, field: string) => {
    if (action === 'add' && !errors.includes(field)) {
      setErrors([...errors, field]);
    }

    if (action === 'remove' && errors.includes(field)) {
      const newErrors = errors.filter(error => error !== field);
      setErrors(newErrors);
    }
  }

  const submit = (e): void => {
    e.preventDefault();

    if (errors.length) {
      setShowErrors(true);
      return;
    }

    if (action === 'Edit') {
      dispatch(editPosition(position, projectId));
    } else {
      dispatch(createPosition(position, projectId));
    }

    close();
  };

  return (
    <Modal open={isOpened}
           modalHeading={<Logo />}
           passiveModal
           onRequestClose={() => close()}
    >
      <div className="c-profile">
        <p className="modal-title m-bottom-40">{action} Position</p>
        <Form onSubmit={e => submit(e)}>

          <InputField type="textInput"
                      label="Position title"
                      placeholder="Enter name"
                      id="position-title"
                      name="name"
                      inputType="text"
                      value={position.name}
                      showErrors={showErrors}
                      handleChange={handleChange}
                      countErrors={countErrors}
          />

          <div className="m-bottom-25">
            <InputField type="textArea"
                        label="Job Description"
                        placeholder="Enter description"
                        rows={10}
                        id="position-description"
                        name="description"
                        inputType="text"
                        value={position.description}
                        showErrors={showErrors}
                        handleChange={handleChange}
                        countErrors={countErrors}
            />
          </div>

          <Button className="c-details-form__button" type="submit" disabled={isPositionSaved}>Save</Button>
        </Form>
      </div>
    </Modal>
  )
}

export default connect()(ManagePosition);
