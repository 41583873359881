import React, { Dispatch, useEffect, useState } from 'react';
import './ImageUploader.scss';

import { connect } from 'react-redux';
import { ButtonKind, FileUploader } from 'carbon-components-react';

import { storage } from '../../services/firebase';
import { request, requestError, requestSuccess } from '../../store/project/project.action';

type propsTypes = {
  type: string,
  resolution: string,
  image: string,
  title?: string,
  optional?: boolean,
  btnKind?: ButtonKind,
  showErrors: boolean,
  handleChange: Function,
  countErrors: Function,
  dispatch: Dispatch<any>
}

function ImageUploader({ type, resolution, image, title, showErrors, handleChange, countErrors, optional, dispatch, btnKind }: propsTypes) {
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (image) {
      countErrors('remove', type);
      setErrorText('');
    } else if (!optional) {
      countErrors('add', type);
      setErrorText('This field is required.');
    }
  }, [image]);

  const uploadImage = (image) => {
    const uploadTask = storage.ref(`/images/${image.name}`).put(image);

    uploadTask.on('state_changed', (snapShot) => {
      dispatch(request());
    }, (err) => {
      const error = {
        title: 'Image upload error.',
        message: 'Something went wrong. Please, try again.'
      }
      dispatch(requestError('image_upload', error));
    }, () => {
      storage.ref('images').child(image.name).getDownloadURL().then(fireBaseUrl => {
        handleChange(type, fireBaseUrl);

        setTimeout(() => dispatch(requestSuccess()), 2000);
      });
    });
  }

  return (
    <div className="c-image-uploader">
      <FileUploader
        accept={['.jpg', '.png', '.jpeg']}
        id="max-file"
        buttonKind={btnKind || 'primary'}
        buttonLabel="Add photo"
        filenameStatus="edit"
        iconDescription="Clear file"
        labelTitle={title}
        onChange={(e: any) => uploadImage(e.target.files[0])}
      />

      {showErrors && !!errorText && (
        <div className="error-text" id="title-photo-error-msg">{errorText}</div>
      )}

      <p className="size-label">{resolution}</p>

      {image && (
        <div className="img-wrapper">
          <img src={image} className="img-responsive" alt="preview banner photo"/>
        </div>
      )}
    </div>
  )
}

export default connect()(ImageUploader);
