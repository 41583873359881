import React from 'react';
import './Logo.scss'

import logo from '../../assets/images/lightbox.svg';
import {Link} from "carbon-components-react";

function Logo() {
  return (
    <div className="c-logo">
      <a href="https://lightboxglobal.com/">
        <img src={logo} alt="Lightbox logo" className="c-logo__image"/>
      </a>
    </div>
  )
}

export default Logo;
