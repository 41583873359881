import React, { Dispatch, useEffect, useState } from 'react';

import { Button, Form, TextInput, Modal } from 'carbon-components-react';
import { useSelector, connect } from 'react-redux';

import { emailRegExp } from '../../../constants/email-regexp';
import { passwordResetEmail } from '../../../store/user/user.action';

import Logo from '../../Logo';

type propsTypes = {
  dispatch: Dispatch<any>,
  isOpened: boolean,
  setModalOpenedStatus: Function
}

const ForgotPassword = ({ dispatch, isOpened, setModalOpenedStatus }: propsTypes) => {
  const { isResetEmailWasSent, userRequestError } = useSelector((state: any) => state.userReducer);

  const [state, setState] = useState({ validateEmail: false });
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (userRequestError.type === 'reset') {
      setErrorMessage(userRequestError.message);
    }
  }, [userRequestError]);

  const handleChange = (newEmail) => {
    setEmail(newEmail);

    if (state.validateEmail && !emailRegExp.test(newEmail)) {
      setErrorMessage('Please enter a valid email address.');
    }
  }

  const sendResetEmail = (e) => {
    e.preventDefault();

    if (emailRegExp.test(email)) {
      dispatch(passwordResetEmail(email))
    } else {
      setState({ validateEmail: true });
    }
  }

  return (
    <Modal open={isOpened}
           modalHeading={<Logo />}
           passiveModal
           onRequestClose={() => setModalOpenedStatus(false)}>
      {isResetEmailWasSent ? (
        <>
          <h3 className="c-auth__title c-auth__title--standalone">Reset Password.</h3>
          <p className="c-auth__text c-auth__text--mb-1">Check your mailbox. A login link was sent to {email}</p>
          <Button className="c-login-form__button"
                  type="button"
                  onClick={() => setModalOpenedStatus(false)}
          >
            Ok
          </Button>
        </>
      ) : (
        <>
          <h3 className="c-auth__title c-auth__title--standalone">Enter your email to reset password.</h3>
          <Form className="c-login-form" onSubmit={e => sendResetEmail(e)}>
            <TextInput labelText="Email"
                       placeholder="Email"
                       id="reset-email"
                       type="email"
                       className="auth__input"
                       invalid={!!errorMessage}
                       invalidText={errorMessage}
                       onChange={e => handleChange(e.target.value)}
            />
            <Button className="c-login-form__button" type="submit">Send</Button>
          </Form>
        </>
      )}
    </Modal>
  )
}

export default connect()(ForgotPassword);
