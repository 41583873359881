export const getQueryParams = (excludedLinkKeys: string[] = []): { [key: string]: any } => {
  const queryParams = new URLSearchParams(window.location.search);

  const params = Array.from(queryParams.keys()).reduce((acc, val) => ({ ...acc, [val]: queryParams.get(val) }), {});

  let queryLink = '';

  Object.keys(params).forEach(key => {
    if (!excludedLinkKeys.includes(key)) {
      queryLink += `${queryLink.length ? '&' : '?'}${key}=${params[key]}`;
    }
  });

  return { params, queryLink }
}
